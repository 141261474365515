<template>
    <div>
        <CSBreadcrumb/>
        <div class="filter-panel">
            <div
                    style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
            >
                <input
                        type="text"
                        placeholder="搜索水表编号/表箱号/位置/备注"
                        style="
            color: #000;
            width: 260px;
            height: 30px;
            padding: 5px 28px 5px 10px;
            border-radius: 3px;
            border: 1px solid #999999;
            margin-right: 30px;
          "
                        v-model="search"
                />
            </div>
            <button type="button" class="btn btn-primary btn-sm" @click="query()">
                查询
            </button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="60">
                <template v-slot:thead>
                    <tr>
                        <th>关联时间</th>
                        <th>水表编号</th>
                        <th>表箱号</th>
                        <th>位置</th>
                        <th>备注</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in meterList" :key="item.id">
                        <td>
                            {{ item.createDate || '-'}}
                        </td>
                        <td>
                            {{ item.meterNo || '-'}}
                        </td>
                        <td>
                            {{ item.meterBoxNo || '-'}}
                        </td>
                        <td>
                            {{ item.locationName || '-'}}
                        </td>
                        <td v-if="item.commit">
                            <span class="allow-click" @click="lookComment(item.commit)">
                                查看
                            </span>
                        </td>
                        <td v-else>
                            -
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination" componentName="Pagination"></Pagination>
        </div>
    </div>
</template>

<script>
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination";
    import {
        queryMeterByGroupIdUrl
    } from "@/requestUrl";

    export default {
        name: "GroupWater",
        components: {
            CSBreadcrumb,
            CSTable,
            Pagination
        },
        created() {
            let params = this.$route.query;
            this.type = params.type;
            this.groupId = params.groupId;
            this.query();
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.query(pageNo);
            });
        },
        data() {
            return {
                groupId: "",
                type: "",
                search: "",
                meterList:[]
            }
        },
        methods:{
            query(pageNo = 1,pageSize =10){
                this.$fly
                    .post(queryMeterByGroupIdUrl, {
                        search: this.search, //搜索框内容
                        pageSize, //页面大小
                        pageNo, //页码
                        groupId:this.groupId,
                        type:this.type,
                        regionCode: this.$vc.getCurrentRegion().code, //区域编码
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if (pageNo == 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total || res.data.datas.length,
                                currentPage: pageNo,
                                pageSize: pageSize,
                            });
                        }
                        this.meterList = res.data.datas;
                    });
            },
            lookComment: function lookComment(comment) {
                this.$CSDialog.alert({
                    title: '查看备注',
                    message: comment
                });
            },
        },
    }
</script>

<style scoped>

</style>
